// import * as style from './TableBlock.css';
import BlockHeader from '@component/BlockHeader';
import Box from '@componentPrimitives/Box';
import { BlockContainer } from '@components/Blocks';
import { createBlock } from '@lib/features/blocks/blocks';
import Txt from '@primitive/Txt';
import { Table, TableBody, TableCell, TableRow } from '@primitives';

const nbsp = <>&nbsp;</>;

const TableBlock = createBlock<'blocks_table_BlockType'>(({ heading, content, table }) => {
  const { columns = [], rows = [] } = table ?? {};

  return (
    <BlockContainer maxWidth="md">
      <BlockHeader {...{ heading, content }} />

      <Box bordered rounded>
        <Table>
          <Box as="thead" colorSet="primary" paper>
            <TableRow>
              {columns.map(({ align, heading, width }, i) => (
                <TableCell
                  key={i}
                  sx={{
                    textAlign: align ?? 'left',
                    ...(i && {
                      borderLeftWidth: 1,
                      borderLeftStyle: 'solid',
                      borderLeftColor: 'divider',
                    }),
                  }}
                  width={width ?? 'auto'}>
                  <Txt bold variant="bodyAlt" as="span">
                    {heading || nbsp}
                  </Txt>
                </TableCell>
              ))}
            </TableRow>
          </Box>
          <TableBody>
            {rows.map((row, i) => (
              <TableRow key={i}>
                {row.map((cellContent, j) => (
                  <TableCell
                    key={j}
                    sx={{
                      textAlign: columns[j].align ?? 'left',
                      ...(i < rows.length - 1 && { borderBottomColor: 'divider' }),
                      ...(j && {
                        borderLeftWidth: 1,
                        borderLeftStyle: 'solid',
                        borderLeftColor: 'divider',
                      }),
                    }}
                    {...(!j && { component: 'th', scope: 'row' })}>
                    <Txt>{cellContent || nbsp}</Txt>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </BlockContainer>
  );
});

export default TableBlock;
