import Box, { BoxProps } from '@primitive/Box';
import Grid, { GridColProps } from '@componentPrimitives/Grid';
import Txt, { TxtProps } from '@componentPrimitives/Txt';
// import Txt, { TxtProps } from '@primitive/Txt';

export type BlockHeaderProps = BoxProps<
  'div',
  {
    heading?: Maybe<string>;
    HeadingProps?: TxtProps<React.ElementType>;
    content?: Maybe<string>;
    ContentProps?: TxtProps<React.ElementType>;
    cta?: React.ReactNode;
    variant?: 'split' | 'standard';
  }
>;

const BlockHeader = ({
  heading,
  HeadingProps,
  content,
  ContentProps,
  children,
  cta,
  variant = 'standard',
  ...props
}: BlockHeaderProps) => {
  const hasContent = !!content;
  const isSplit = variant === 'split';
  const breakpoint = 'md';
  const leftCol: GridColProps = isSplit ? { [breakpoint]: 7 } : {};
  const rightCol: GridColProps = isSplit
    ? {
        [breakpoint]: 5,
        cx: {
          textAlign: { [breakpoint]: 'right' },
          mT: { xs: 'sm', [breakpoint]: 'none' },
        },
      }
    : {};

  return (
    <Box cx={{ marginBottom: 'lg' }} {...props}>
      <Grid cx={{ alignItems: hasContent ? 'start' : 'end' }}>
        <Grid.Col {...leftCol}>
          <Txt variant="h5" as="h2" autoMargin={hasContent} {...HeadingProps}>
            {heading}
          </Txt>
        </Grid.Col>
        {content && (
          <Grid.Col {...leftCol}>
            <Txt html {...ContentProps}>
              {content}
            </Txt>
          </Grid.Col>
        )}
        {cta && <Grid.Col {...rightCol}>{cta}</Grid.Col>}
      </Grid>
      {children}
    </Box>
  );
};

export default BlockHeader;
